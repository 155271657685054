export default class Footer {
  constructor() {
    this.footer = document.querySelector('.footer');

    if (this.footer) {
      this.scrollToTopButton = this.footer.querySelector('.footer__scroll_top');

      if (this.scrollToTopButton) {
        this.scrollToTopButton.addEventListener('click', () => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        });
      }
    }
  }
}
