export default class FaqSearch {
  constructor() {
    this.faqSearchers = document.querySelectorAll('.faq-search');
    this.faqSearchers.forEach(faqSearcher => new FaqSearchComponent(faqSearcher));
  }
}

class FaqSearchComponent {
  constructor(searcher) {
    const searchInput = searcher.querySelector('.faq-search__input input');
    const faqGroups = searcher.querySelectorAll('.faq-search__group');
    const emptyInfo = searcher.querySelector('.faq-search__empty-info');

    searchInput.addEventListener('input', () => {
      const matchedGroupsCount = Array.from(faqGroups).reduce((groupsCount, group) => {
        const groupItems = group.querySelectorAll('.faq-search__item');
        const matchedItemsCount = Array.from(groupItems).reduce((itemsCount, item) => {
          const itemTitle = item.querySelector('.faq-card__header__title').innerText;
          const itemText = item.querySelector('.faq-card__content__text').innerText;

          if (`${itemTitle.toLowerCase()} ${itemText.toLowerCase()}`.includes(searchInput.value.trim().toLowerCase())) {
            item.classList.remove('d-none');
            return itemsCount + 1;
          } else {
            item.classList.add('d-none');
            return itemsCount;
          }
        }, 0);

        if (matchedItemsCount === 0) {
          group.classList.add('d-none');
          return groupsCount;
        } else {
          group.classList.remove('d-none');
          return groupsCount + 1;
        }
      }, 0);

      if (matchedGroupsCount === 0) {
        emptyInfo.classList.remove('d-none');
      } else {
        emptyInfo.classList.add('d-none');
      }
    });
  }
}
