import * as debounce from 'lodash.debounce';
import * as throttle from 'lodash.throttle';
import ToastMessage from '../shared/toast-message';
import { breakpoints, hideElement, isDevMode, showElement, translations } from '../../shared';


export default class CampaignsSearcher {
  constructor() {
    this.searcher = document.querySelector('.campaigns-searcher');
    if (!this.searcher) {
      return;
    }


    this.campaignsList = this.searcher.querySelector('.campaigns-searcher__list');
    this.loadMoreBtn = this.searcher.querySelector('.campaigns-searcher__load-btn');
    this.clearFiltersBtn = this.searcher.querySelector('.campaigns-searcher__clear-filters');
    this.emptyListBanner = this.searcher.querySelector('.campaigns-searcher__empty')
    this.searchInput = this.searcher.querySelector(`input[name='search']`);
    this.categoriesFilter = this.searcher.querySelectorAll(`input[name='categories']`);
    this.sortFilter = this.searcher.querySelectorAll(`input[name='sortBy']`);
    this.fundedFilter = this.searcher.querySelector(`input[name='funded']`);
    this.favouritesFilter = this.searcher.querySelector(`input[name='favourites']`);
    this.voivodeshipsFilter = this.searcher.querySelector(`input[name='voivodeships']`);

    this.campaignsLoader = this.searcher.querySelector('.campaigns-page__campaigns__loader');

    this.sortingFiltersHeader = this.searcher.querySelector(`[data-collapse-control='campaigns-sorting-filter']`);
    this.sortingFiltersContent = this.searcher.querySelector(`[data-collapse-content='campaigns-sorting-filter']`);

    this.categoriesFiltersHeader = this.searcher.querySelector(`[data-collapse-control='campaigns-categories-filter']`);
    this.categoriesFiltersContent = this.searcher.querySelector(`[data-collapse-content='campaigns-categories-filter']`);

    this.filterInputs = this.searcher.querySelectorAll(`input[data-default-value]`);

    this.campaignsUrl = this.searcher.dataset.campaignsUrl;
    this.campaingsAPiUrl = this.searcher.dataset.campaignsApiUrl;

    this.searchParams = this.defaultSearchParams;

    this.initFiltersState();
    this.initFiltersListeners();
  }

  initFiltersState() {
    this.filtersCollapsed = false;
    this.searchParams.search = this.searchInput.value;
    this.searchParams.funded = this.fundedFilter.value === 'true';
    this.searchParams.voivodeships = this.voivodeshipsFilter.value;

    this.updateFiltersCollapse();

    const selectedCategories = document.querySelectorAll(`input[name='categories']:checked`);
    selectedCategories.forEach(input => {
      this.searchParams.categories.add(input.value);
      if (!this.hasInputDefaultValue(input)) {
        showElement(this.clearFiltersBtn);
      }
    });

    const selectedSorting = document.querySelector(`input[name='sortBy']:checked`);
    this.searchParams.sortBy = selectedSorting.value;

    if (
      this.searchParams.sortBy !== this.defaultSearchParams.sortBy ||
      this.searchParams.search !== this.defaultSearchParams.search ||
      this.searchParams.funded !== this.defaultSearchParams.funded ||
      this.searchParams.favourites !== this.defaultSearchParams.favourites ||
      this.searchParams.voivodeships !== this.defaultSearchParams.voivodeships
    ) {
      showElement(this.clearFiltersBtn);
    }
  }

  initFiltersListeners() {
    this.searchInput.addEventListener('input', debounce(() => {
      this.searchParams.search = this.searchInput.value;
      this.loadCampaigns(true);
    }, 1250));

    this.categoriesFilter.forEach(input => {
      input.addEventListener('change', () => {
        if (this.searchParams.categories.has(input.value)) {
          this.searchParams.categories.delete(input.value);
        } else {
          this.searchParams.categories.add(input.value);
        }

        this.loadCampaigns(true);
      });
    });

    this.sortFilter.forEach(input => {
      input.addEventListener('change', () => {
        this.searchParams.sortBy = input.value;
        this.loadCampaigns(true);
      })
    });

    this.fundedFilter.addEventListener('change', () => {
      this.searchParams.funded = this.fundedFilter.checked;
      this.loadCampaigns(true);
    });

    this.favouritesFilter.addEventListener('change', () => {
      this.searchParams.favourites = this.favouritesFilter.checked;
      this.loadCampaigns(true);
    });

    this.voivodeshipsFilter.addEventListener('input', () => {
      this.searchParams.voivodeships = this.voivodeshipsFilter.value;
      this.loadCampaigns(true);
    });

    this.loadMoreBtn.addEventListener('click', async () => {
      this.searchParams.page++;
      this.loadCampaigns();
    });

    this.clearFiltersBtn.addEventListener('click', () => {
      this.clearFilters();
    });

    window.addEventListener('resize', throttle(() => {
      this.updateFiltersCollapse();
    }, 100));
  }

  async loadCampaigns(replaceCurrent = false) {
    showElement(this.campaignsLoader);
    hideElement(this.loadMoreBtn);
    hideElement(this.emptyListBanner);
    this.checkDefaultFilters();

    if (replaceCurrent) {
      this.searchParams.page = 1;
      this.campaignsList.innerHTML = '';
      window.scrollTo({top: this.campaignsList.offsetTop - 140});
    }

    try {
      const res = await fetch(this.getSearchUrl());
      if (!res.ok) {
        throw new Error(translations.get('loadingCampaignsError'));
      }

      const data = await res.json();
      this.campaignsList.innerHTML += data.htmlContent;

      data.isLast ? hideElement(this.loadMoreBtn) : showElement(this.loadMoreBtn);

      if (data.htmlContent === '') {
        showElement(this.emptyListBanner);
      } else {
        hideElement(this.emptyListBanner);
      }

      this.updateSearchUrl();
    } catch (e) {
      isDevMode && console.log(e);
      new ToastMessage(e.message, 'error');
    } finally {
      hideElement(this.campaignsLoader);
    }
  }

  getSearchUrl() {
    const searchParams = new URLSearchParams({
      page: this.searchParams.page,
      search: this.searchParams.search,
      categories: Array.from(this.searchParams.categories),
      voivodeships: this.searchParams.voivodeships,
      sortBy: this.searchParams.sortBy,
      funded: this.searchParams.funded,
      favourites: this.searchParams.favourites,
    });

    return `${this.campaingsAPiUrl}?${searchParams}`;
  }

  updateSearchUrl() {
    const searchParams = new URLSearchParams();
    let url = this.campaignsUrl;

    if (this.searchParams.categories.size === 1) {
      url += `/${this.searchParams.categories.values().next().value}`
    } else if (this.searchParams.categories.size > 1) {
      searchParams.append('categories', Array.from(this.searchParams.categories).toString());
    }

    if (this.searchParams.search !== this.defaultSearchParams.search) {
      searchParams.append('search', this.searchParams.search);
    }

    if (this.searchParams.funded) {
      searchParams.append('funded', 'true');
    }

    if (this.searchParams.sortBy !== this.defaultSearchParams.sortBy) {
      searchParams.append('sortBy', this.searchParams.sortBy);
    }

    if (this.searchParams.favourites !== this.defaultSearchParams.favourites) {
      searchParams.append('favourites', 'true');
    }

    if (this.searchParams.voivodeships !== this.defaultSearchParams.voivodeships) {
      searchParams.append('voivodeships', this.searchParams.voivodeships);
    }

    if (Array.from(searchParams).length > 0) {
      url += `?${searchParams.toString()}`;
    }

    history.replaceState({}, null, url);
  }

  checkDefaultFilters() {
    for (const input of this.filterInputs) {
      if (!this.hasInputDefaultValue(input)) {
        showElement(this.clearFiltersBtn);
        return;
      }
    }

    hideElement(this.clearFiltersBtn);
  }

  clearFilters() {
    this.searchParams = this.defaultSearchParams;
    this.filterInputs.forEach(input => {
      this.setInputDefaultValue(input);
    });

    hideElement(this.clearFiltersBtn);
    this.loadCampaigns(true);
  }

  setInputDefaultValue(input) {
    if (input.classList.contains('dropdown__input')) {
      input.dispatchEvent(new CustomEvent('dropdownReset'));
    } else if (input.type === 'text') {
      input.value = input.dataset.defaultValue;
    } else if (input.type === 'checkbox' || input.type === 'radio') {
      input.checked = input.dataset.defaultValue === 'true';
    }
  }

  hasInputDefaultValue(input) {
    if (input.type === 'text' || input.type === 'hidden') {
      if (input.value !== input.dataset.defaultValue) {
        return false;
      }
    } else if (input.type === 'checkbox' || input.type === 'radio') {
      if (input.checked !== (input.dataset.defaultValue === 'true')) {
        return false;
      }
    }
    return true;
  }

  updateFiltersCollapse() {
    if (window.innerWidth < breakpoints.lg && !this.filtersCollapsed) {
      this.collapseFilters();
      this.filtersCollapsed = true;
    } else if (window.innerWidth > breakpoints.lg && this.filtersCollapsed) {
      this.expandFilters();
      this.filtersCollapsed = false;
    }
  }

  expandFilters() {
    this.sortingFiltersHeader.classList.add('active');
    this.sortingFiltersContent.classList.add('active');
    this.categoriesFiltersHeader.classList.add('active');
    this.categoriesFiltersContent.classList.add('active');
  }

  collapseFilters() {
    this.sortingFiltersHeader.classList.remove('active');
    this.sortingFiltersContent.classList.remove('active');
    this.categoriesFiltersHeader.classList.remove('active');
    this.categoriesFiltersContent.classList.remove('active');
  }

  get defaultSearchParams() {
    return {
      page: 1,
      categories: new Set(),
      search: this.searchInput.dataset.defaultValue,
      voivodeships: this.voivodeshipsFilter.dataset.defaultValue,
      sortBy: this.searcher.querySelector(`input[name='sortBy'][data-default-value='true']`).value,
      funded: this.fundedFilter.dataset.defaultValue === 'true',
      favourites: this.favouritesFilter.dataset.defaultValue === 'true'
    }
  }
}
