import ToastMessage from '../shared/toast-message';
import { translations } from '../../shared';


export default class CopyTextBtn {
  constructor() {
    this.buttons = document.querySelectorAll('.copy-text-btn');
    this.buttons.forEach(button => {
      button.addEventListener('click', async () => {
        await navigator.clipboard.writeText(button.dataset.copyText);
        new ToastMessage(translations.get('copiedToClipboard'), 'success');
      });
    });
  }
}
