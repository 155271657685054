import { hideElement, showElement, translations } from '../../shared';
import ToastMessage from '../shared/toast-message';


export default class CampaignPreview {
  constructor() {
    this.campaignPreview = document.querySelector('.campaign-preview');
    if (!this.campaignPreview) {
      return;
    }

    this.campaignId = this.campaignPreview.dataset.campaignId;
    this.paymentsApi = this.campaignPreview.dataset.paymentsApi;
    this.commentsApi = this.campaignPreview.dataset.commentsApi;

    this.paymentsPage = 1;
    this.commentsPage = 1;

    this.paymentsSortBy = null;

    this.fetchingPayments = false;
    this.fetchingComments = false;

    this.paymentsList = this.campaignPreview.querySelector('.campaign-details__payments__list');
    this.commentsList = this.campaignPreview.querySelector('.campaign-details__comments__list');

    this.paymentsListLoadBtn = this.campaignPreview.querySelector('.campaign-details__payments__load-more');
    this.commentsListLoadBtn = this.campaignPreview.querySelector('.campaign-details__comments__load-more');

    this.paymentsTabButtons = this.campaignPreview.querySelectorAll('.campaign-details__payments__header__tabs__item');

    this.initListeners();
  }

  initListeners() {
    this.paymentsListLoadBtn.addEventListener('click', async () => {
      if (!this.fetchingPayments) {
        this.fetchingPayments = true;
        await this.loadPayments();
        this.fetchingPayments = false;
      }
    });

    this.commentsListLoadBtn.addEventListener('click', async () => {
      if (!this.fetchingComments) {
        this.fetchingComments = true;
        await this.loadComments();
        this.fetchingComments = false;
      }
    });

    this.paymentsTabButtons.forEach(button => {
      if (button.classList.contains('campaign-details__payments__header__tabs__item--active')) {
        this.paymentsSortBy = button.dataset.sortBy;
      }

      button.addEventListener('click', async () => {
        if (this.paymentsSortBy !== button.dataset.sortBy && !this.fetchingPayments) {
          this.paymentsList.innerHTML = '';
          this.paymentsPage = 0;
          this.paymentsSortBy = button.dataset.sortBy;

          this.paymentsTabButtons.forEach(b => b.classList.remove('campaign-details__payments__header__tabs__item--active'));
          button.classList.add('campaign-details__payments__header__tabs__item--active');

          this.fetchingPayments = true;
          await this.loadPayments();
          this.fetchingPayments = false;
        }
      });
    });
  }

  async loadPayments() {
    this.paymentsListLoadBtn.disabled = true;
    this.paymentsPage++;

    try {
      const res = await fetch(`${this.paymentsApi}?page=${this.paymentsPage}&sortBy=${this.paymentsSortBy}`);
      if (!res.ok) {
        throw 'Unable to load payments';
      }

      const data = await res.json();
      this.paymentsList.innerHTML += data.htmlContent;
      data.isLast ? hideElement(this.paymentsListLoadBtn) : showElement(this.paymentsListLoadBtn);
    } catch (e) {
      this.paymentsPage--;
      new ToastMessage(translations.get('campaignPaymentsError'), 'error');
    } finally {
      this.paymentsListLoadBtn.disabled = false;
    }
  }

  async loadComments() {
    this.commentsListLoadBtn.disabled = true;
    this.commentsPage++;

    try {
      const res = await fetch(`${this.commentsApi}?page=${this.commentsPage}`);
      if (!res.ok) {
        throw 'Unable to load comments';
      }

      const data = await res.json();
      this.commentsList.innerHTML += data.htmlContent;
      data.isLast ? hideElement(this.commentsListLoadBtn) : showElement(this.paymentsListLoadBtn);
    } catch (e) {
      this.commentsPage--;
      new ToastMessage(translations.get('campaignCommentsError'), 'error')
    } finally {
      this.commentsListLoadBtn.disabled = false;
    }
  }
}
