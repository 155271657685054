export default class DomElement {
  constructor(node) {
    this.nativeElement = node;
    this.elementPlaceholder = document.createElement('render-placeholder');
  }

  remove() {
    if (this.nativeElement) {
      this.nativeElement.replaceWith(this.elementPlaceholder);
    }
  }

  render() {
    if (this.nativeElement) {
      this.elementPlaceholder.replaceWith(this.nativeElement);
    }
  }

  static select(query) {
    return Array.from(document.querySelectorAll(query)).map(node => new DomElement(node));
  }
}
