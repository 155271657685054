export default class FaqCard {
  constructor() {
    this.faqCards = document.querySelectorAll('.faq-card');
    this.faqCards.forEach(card => {
      const cardHeader = card.querySelector('.faq-card__header');
      cardHeader.addEventListener('click', () => {
        card.classList.toggle('faq-card--active');
      });
    });
  }
}
