import Swiper from 'swiper';


export default class CampaignsSwiper {
  constructor() {
    this.swipers = document.querySelectorAll('.campaigns-swiper');
    this.swipers.forEach(swiper => {
      new CampaignsSwiperComponent(swiper);
    });
  }
}

class CampaignsSwiperComponent {
  constructor(container) {
    const swiperContainer = container.querySelector('.swiper-container');
    const prevButton = container.querySelector('.campaigns-swiper__prev');
    const nextButton = container.querySelector('.campaigns-swiper__next');
    const pagination = container.querySelector('.campaigns-swiper__pagination');

    const swiper = new Swiper(swiperContainer, {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 16,
      pagination: {
        el: pagination,
        clickable: true
      },
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: true
      // },
      breakpoints: {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        1200: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        1400: {
          slidesPerView: 4,
          slidesPerGroup: 4
        }
      }
    });

    prevButton.addEventListener('click', () => {
      swiper.slidePrev();
    });

    nextButton.addEventListener('click', () => {
      swiper.slideNext();
    });
  }
}
