import * as throttle from 'lodash.throttle';


export default class GoalsTimeline {
  constructor() {
    this.timelines = document.querySelectorAll('.goals-timeline');
    this.timelines.forEach(timeline => new GoalsTimelineComponent(timeline));
  }
}

class GoalsTimelineComponent {
  constructor(node) {
    this.node = node;
    this.groupThreshold = 8;
    this.points = node.querySelectorAll('.goals-timeline__track__point:not(.goals-timeline__track__point--current)')

    this.checkPoints();
    window.addEventListener('resize', throttle(() => {
      this.checkPoints();
    }, 100));
  }

  checkPoints() {
    for (let i = 0; i < this.points.length; i++) {
      if (!this.points[i].classList.contains('goals-timeline__track__point--hidden')) {
        const collapsingPoints = this.getCollapsingPoints(this.points[i], i);
        collapsingPoints.length > 0 && this.groupPoints(this.points[i], collapsingPoints);
      }
    }
  }

  getCollapsingPoints(testingPoint, index) {
    const collapsingPoints = [];
    const testingPointLabel = testingPoint.querySelector('.goals-timeline__track__point__label');
    const testingPointLabelRect = testingPointLabel.getBoundingClientRect();

    for (let i = index; i < this.points.length - 1; i++) {
      const label = this.points[i + 1].querySelector('.goals-timeline__track__point__label');
      const labelRect = label.getBoundingClientRect()

      if (testingPointLabelRect.x + testingPointLabelRect.width + this.groupThreshold > labelRect.x) {
        collapsingPoints.push(this.points[i + 1]);
      } else {
        return collapsingPoints;
      }
    }

    return collapsingPoints;
  }

  groupPoints(targetPoint, points) {
    const targetPointLabel = targetPoint.querySelector('.goals-timeline__track__point__label');
    targetPointLabel.classList.add('goals-timeline__track__point__label--group');
    points.forEach(point => {
      if (!point.classList.contains('goals-timeline__track__point--hidden')) {
        const pointLabels = point.querySelectorAll('.goals-timeline__track__point__label span');
        pointLabels.forEach(pointLabel => targetPointLabel.appendChild(pointLabel.cloneNode(true)));
        point.classList.add('goals-timeline__track__point--hidden')
      }
    });
  }
}
