import DomElement from '../shared/dom-element';
import { getCSRF, isAuthenticated, translations } from '../../shared';
import MicroModal from 'micromodal';
import ToastMessage from '../shared/toast-message';


export default class PaymentCard {
  constructor() {
    this.paymentCard = document.querySelector('.payment-card');
    this.summaryModal = document.querySelector('.payment-summary');

    if (this.paymentCard && this.summaryModal) {
      this.initPaymentCard();
    }
  }

  initPaymentCard() {
    this.findElements();
    this.initState();
    this.initListeners();
  }

  findElements() {
    this.paymentTypeRadios = this.paymentCard.querySelectorAll(`[name='payment_type']`);
    this.recurringFormParts = DomElement.select(`[data-part='recurring']`);
    this.predefinedAmounts = this.paymentCard.querySelectorAll(`[name='predefined_amount']`);
    this.customAmountInput = this.paymentCard.querySelector(`.payment-card__custom-amount`);
    this.paymentDetails = new DomElement(this.paymentCard.querySelector('.payment-card__content'));
    this.authContent = new DomElement(this.paymentCard.querySelector('.payment-card__auth'));
    this.signature = new DomElement(this.paymentCard.querySelector('.payment-card__user-details__signature__input'));
    this.anonymouscheckbox = this.paymentCard.querySelector(`[name='anonymous_payment']`);
    this.paymentSubmit = this.paymentCard.querySelector('.payment-card__submit');
    this.paymentAmounts = new DomElement(this.paymentCard.querySelector('.payment-card__payment__amounts'));
    this.paymentTypes = new DomElement(this.paymentCard.querySelector('.payment-card__payment__types'));
    this.paymentReward = new DomElement(this.paymentCard.querySelector('.payment-card__payment__reward'));
    this.paymentRewardIdInput = this.paymentCard.querySelector(`[name='reward_id']`);
    this.paymentAmountInput = this.paymentCard.querySelector(`[name='amount']`);
    this.paymentRewardRemoveBtn = this.paymentCard.querySelector('.payment-card__payment__reward__remove');
    this.paymentRewardAmount = this.paymentCard.querySelector('.payment-card__payment__reward__amount');

    this.paymentCardNumberInput = this.paymentCard.querySelector(`[name='card_number']`);
    this.paymentCardExpireInput = this.paymentCard.querySelector(`[name='card_date']`);
    this.paymentCardCvvInput = this.paymentCard.querySelector(`[name='card_cvv']`);

    this.summaryForm = this.summaryModal.querySelector('.payment-summary__content');
    this.supportAmountSection = new DomElement(this.summaryModal.querySelector('.payment-summary__support-amount'));
    this.supportPlatformAgreeButton = new DomElement(this.summaryModal.querySelector('.payment-summary__platform-support__agree'));
    this.supportPlatformDeclineButton = new DomElement(this.summaryModal.querySelector('.payment-summary__platform-support__decline'));
    this.summaryAmountInput = this.summaryModal.querySelector(`[name='amount']`);
    this.summarySupportAmountInput = this.summaryModal.querySelector(`[name='amount_of_support']`);
    this.summaryPredefinedSupportDropdown = this.summaryModal.querySelector(`[name='predefined-support-amount']`);
    this.summaryPercentageSupportDropdown = this.summaryModal.querySelector(`[name='percent_of_support_dropdown']`);
    this.summaryPercentageSupportInput = this.summaryModal.querySelector(`[name='percent_of_support']`);
    this.summaryCustomSupportInput = this.summaryModal.querySelector(`[name='support-custom-amount']`);
    this.summaryPaymentTypeInput = this.summaryModal.querySelector(`[name='payment_type']`);
    this.summarySupportCustomAmount = new DomElement(this.summaryModal.querySelector('.payment-summary__support-amount__custom'));
    this.summaryOrganizerAmount = this.summaryModal.querySelector('.payment-summary__organizer-amount');
    this.summaryPlatformAmount = this.summaryModal.querySelector('.payment-summary__platform-amount');
    this.summaryTotalAmount = this.summaryModal.querySelector('.payment-summary__total-amount');
    this.summarySubmitAmount = this.summaryModal.querySelector('.payment-summary__submit_amount');
    this.summaryPercentSupportDropdown = new DomElement(this.summaryModal.querySelector('.payment-summary__support-amount__dropdown--percent'));
    this.summaryAmountSupportDropdown = new DomElement(this.summaryModal.querySelector('.payment-summary__support-amount__dropdown--amount'));
    this.summaryReward = new DomElement(this.summaryModal.querySelector('.payment-summary__reward'));
    this.summaryRewardCard = this.summaryModal.querySelector('.payment-summary__reward__card');

    this.campaignRewardsButtons = document.querySelectorAll('.campaign-aside__rewards__item__select');
    this.paywallScriptContainer = document.querySelector('#imoje-widget-container');
  }

  initState() {
    this.isPlatformSupport = this.paymentCard.dataset.isPlatformSupport === 'true';
    this.paymentDetails.remove();
    this.authContent.remove();
    this.supportPlatformAgreeButton.remove();
    this.summarySupportCustomAmount.remove();
    this.paymentTypes.remove();
    this.summaryPercentSupportDropdown.remove();
    this.summaryAmountSupportDropdown.remove();
    this.paymentReward.remove();
    this.summaryReward.remove();
  }

  initListeners() {
    this.paymentCard.addEventListener('submit', (e) => {
      e.preventDefault();
      this.openSummaryModal();
    });

    this.campaignRewardsButtons.forEach(rewardButton => {
      rewardButton.addEventListener('click', () => {
        this.selectReward(rewardButton);
      });
    });

    if (!this.isPlatformSupport) {
      this.paymentRewardRemoveBtn.addEventListener('click', () => {
        this.removeReward();
      });

      // anonymous payment checkbox
      this.anonymouscheckbox.addEventListener('change', () => {
        if (this.anonymouscheckbox.checked) {
          this.signature.remove();
        } else {
          this.signature.render();
        }
      });

      // platform support, predefined amounts
      this.summaryPredefinedSupportDropdown.addEventListener('input', () => {
        if (this.summaryPredefinedSupportDropdown.value === 'custom') {
          this.summarySupportCustomAmount.render();
          this.summarySupportAmountInput.setAttribute('value', this.summaryCustomSupportInput.value);
        } else {
          this.summarySupportCustomAmount.remove();
          this.summarySupportAmountInput.setAttribute('value', this.summaryPredefinedSupportDropdown.value);
        }

        this.updateSummary();
      });

      // button to enable platform support
      this.supportPlatformAgreeButton.nativeElement.addEventListener('click', () => {
        this.supportAmountSection.render();
        this.supportPlatformAgreeButton.remove();
        this.supportPlatformDeclineButton.render();

        // single payment with fixed amount
        if (this.summaryPaymentTypeInput.value === 'single') {
          if (this.summaryPredefinedSupportDropdown.value === 'custom') {
            // custom support amount from user input
            this.summarySupportAmountInput.setAttribute('value', this.summaryCustomSupportInput.value);
          } else {
            // predefined amounts from dropdown
            this.summarySupportAmountInput.setAttribute('value', this.summaryPredefinedSupportDropdown.value)
          }
        } else {
          // percentage support amount from dropdown
          this.summaryPercentageSupportInput.setAttribute('value', this.summaryPercentageSupportDropdown.value);
        }

        this.updateSummary();
      });

      // button to hide platform support
      this.supportPlatformDeclineButton.nativeElement.addEventListener('click', () => {
        this.supportAmountSection.remove();
        this.supportPlatformAgreeButton.render();
        this.supportPlatformDeclineButton.remove();
        this.summarySupportAmountInput.setAttribute('value', '');
        this.summaryPercentageSupportInput.setAttribute('value', '');
        this.updateSummary();
      });

      // platform support, custom amount input
      this.summaryCustomSupportInput.addEventListener('input', () => {
        this.summarySupportAmountInput.setAttribute('value', this.summaryCustomSupportInput.value);
        this.updateSummary();
      });

      // card payment elements, available only for authenticated users
      if (isAuthenticated) {
        // percentage platform support dropdown
        this.summaryPercentageSupportDropdown.addEventListener('input', () => {
          this.summaryPercentageSupportInput.setAttribute('value', this.summaryPercentageSupportDropdown.value);
          this.updateSummary();
        });
      }
    }

    // changing payment type, single or recurring
    this.paymentTypeRadios.forEach(radio => {
      radio.addEventListener('change', () => {
        this.togglePaymentType(radio.value);
      });
    });

    // predefined payment amounts that user can select
    this.predefinedAmounts.forEach(radio => {
      radio.addEventListener('change', () => {
        this.customAmountInput.value = '';
        this.customAmountInput.dispatchEvent(new Event('input'));
        this.selectedPredefinedAmountRadio = radio;
        this.paymentAmountInput.setAttribute('value', radio.value);
        this.paymentTypes.render();
      });
    });

    // custom amount of payment
    this.customAmountInput.addEventListener('input', () => {
      if (this.selectedPredefinedAmountRadio) {
        this.selectedPredefinedAmountRadio.checked = false;
        this.selectedPredefinedAmountRadio = null;
      }

      this.paymentTypes.render();
      this.paymentAmountInput.setAttribute('value', Number(this.customAmountInput.value) * 100);
    });

    // handle card payment submit and show card data modal
    this.summaryForm.addEventListener('submit', async (e) => {
      if (this.summaryPaymentTypeInput.value === 'recurring') {
        e.preventDefault();
        if (!this.startedCardPayment) {
          this.startedCardPayment = true;
          await this.startCardPayment();
          this.startedCardPayment = false;
        }
      }
    });
  }

  updateSummary() {
    if (this.summaryPaymentTypeInput.value === 'single') {
      let totalAmount;

      if (!this.isPlatformSupport) {
        totalAmount = ((Number(this.summaryAmountInput.value) + Number(this.summarySupportAmountInput.value)) / 100).toFixed(0);
        this.summaryPlatformAmount.innerHTML = (this.summarySupportAmountInput.value / 100).toFixed(0);
      } else {
        totalAmount = Number(this.summaryAmountInput.value) / 100;
      }

      this.summaryOrganizerAmount.innerHTML = (this.summaryAmountInput.value / 100).toFixed(0);
      this.summaryTotalAmount.innerHTML = totalAmount;
      this.summarySubmitAmount.innerHTML = totalAmount;
    } else {
      const paymentAmount = Number(this.summaryAmountInput.value);
      let totalAmount;

      if (!this.isPlatformSupport) {
        const supportPercentage = this.summaryPercentageSupportInput.value;
        totalAmount = paymentAmount + paymentAmount * supportPercentage / 100;
        this.summaryPlatformAmount.innerHTML = Math.ceil((totalAmount - paymentAmount) / 100);
      } else {
        totalAmount = paymentAmount;
      }

      this.summaryOrganizerAmount.innerHTML = paymentAmount / 100;
      this.summaryTotalAmount.innerHTML = Math.ceil(totalAmount / 100);
      this.summarySubmitAmount.innerHTML = Math.ceil(totalAmount / 100);
    }

    if (!this.isPlatformSupport) {
      const summaryPlatformAmountValueContainer = this.summaryPlatformAmount.closest('.payment-summary__costs__line__value');
      if (this.summarySupportAmountInput.value || this.summaryPercentageSupportInput.value) {
        summaryPlatformAmountValueContainer.classList.remove('payment-summary__costs__line__value--danger');
      } else {
        summaryPlatformAmountValueContainer.classList.add('payment-summary__costs__line__value--danger');
      }
    }
  }

  openSummaryModal() {
    this.summaryForm.reset();

    const formData = new FormData(this.paymentCard);
    for (const [key, val] of formData.entries()) {
      const control = this.summaryForm.querySelector(`input[name='${key}']`);
      if (control) {
        control.setAttribute('value', val.toString());
      }
    }

    MicroModal.show('payment-summary-modal', {
      openClass: 'modal--active',
      disableFocus: true,
    });

    this.updateSummary();
  }

  togglePaymentType(paymentType) {
    if (paymentType === 'single') {
      this.showSinglePaymentForm();
    } else {
      this.showRecurringPaymentForm();
    }
  }

  showSinglePaymentForm() {
    this.paymentDetails.render();
    this.recurringFormParts.forEach(domElement => domElement.remove());
    this.summaryPercentSupportDropdown.remove();
    this.summaryAmountSupportDropdown.render();

    if (!isAuthenticated) {
      this.authContent.remove();
    }
  }

  showRecurringPaymentForm() {
    if (isAuthenticated) {
      this.paymentDetails.render();
      this.recurringFormParts.forEach(domElement => domElement.render());
      this.summaryAmountSupportDropdown.remove();
      this.summaryPercentSupportDropdown.render();
    } else {
      this.paymentDetails.remove();
      this.authContent.render();
    }
  }

  selectReward(rewardBtn) {
    const rewardCard = rewardBtn.closest('.campaign-aside__rewards__item').cloneNode(true);

    this.summaryPaymentTypeInput.setAttribute('value', 'single');
    this.paymentRewardIdInput.setAttribute('value', rewardBtn.dataset.id);
    this.paymentAmountInput.setAttribute('value', rewardBtn.dataset.price);

    rewardCard.querySelector('.campaign-aside__rewards__item__select').remove();

    this.paymentCard.reset();
    this.paymentAmounts.remove();
    this.paymentTypes.remove();
    this.paymentReward.render();
    this.summaryReward.render();
    this.showSinglePaymentForm();
    this.paymentRewardAmount.innerHTML = rewardBtn.dataset.price / 100;
    this.summaryRewardCard.innerHTML = '';
    this.summaryRewardCard.appendChild(rewardCard);

    window.location.hash = '_';
    window.location.hash = 'payment';
  }

  removeReward() {
    this.paymentRewardIdInput.setAttribute('value', '');
    this.paymentAmountInput.setAttribute('value', '');
    this.paymentDetails.remove();
    this.paymentReward.remove();
    this.summaryReward.remove();
    this.paymentAmounts.render();
  }

  async startCardPayment() {
    const formData = new FormData(this.summaryForm);
    formData.append('_token', getCSRF());

    try {
      const res = await fetch(this.summaryForm.dataset.paymentApi, {method: 'post', body: formData});
      if (!res.ok) {
        throw new Error('Unable to create card payment');
      }

      const data = await res.json();
      const paymentScript = document.createElement('script');
      paymentScript.setAttribute('src', this.summaryForm.dataset.paywallUrl);
      paymentScript.setAttribute('id', 'imoje-widget__script');

      paymentScript.setAttribute('data-merchant-id', data.merchantId);
      paymentScript.setAttribute('data-service-id', data.serviceId);
      paymentScript.setAttribute('data-amount', data.amount);
      paymentScript.setAttribute('data-currency', data.currency);
      paymentScript.setAttribute('data-order-id', data.orderId);
      paymentScript.setAttribute('data-customer-id', data.customerId);
      paymentScript.setAttribute('data-customer-first-name', data.customerFirstName);
      paymentScript.setAttribute('data-customer-last-name', data.customerLastName);
      paymentScript.setAttribute('data-customer-email', data.customerEmail);
      paymentScript.setAttribute('data-signature', data.signature);
      paymentScript.setAttribute('data-url-return', data.urlReturn);
      paymentScript.setAttribute('data-widget-type', data.widgetType);

      this.paywallScriptContainer.innerHTML = '';
      this.paywallScriptContainer.appendChild(paymentScript);
    } catch (e) {
      new ToastMessage(translations.get('createCardPaymentError'), 'error');
    }
  }
}
