import NewsletterForm from './components/platform/newsletter-form';
import HeroSwiper from './components/platform/hero-swiper';
import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/core';
import CampaignsSwiper from './components/platform/campaigns-swiper';
import ParallaxImage from './components/platform/parallax-image';
import DetailsTabs from './components/platform/details-tabs';
import Footer from './components/platform/footer';
import FaqCard from './components/platform/faq-card';
import FaqSearch from './components/platform/faq-search';
import CampaignsSearcher from './components/platform/campaigns-searcher';
import CampaignPreview from './components/platform/campaign-preview';
import CopyTextBtn from './components/platform/copy-text-btn';
import PaymentCard from './components/platform/payment-card';
import GoalsTimeline from './components/platform/goals-timeline';


SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

window.addEventListener('DOMContentLoaded', () => {
  new CampaignsSearcher();
  new HeroSwiper();
  new CampaignsSwiper();
  new NewsletterForm();
  new PaymentCard();
  new ParallaxImage();
  new DetailsTabs();
  new CampaignPreview();
  new CopyTextBtn();
  new GoalsTimeline();
  new FaqCard();
  new FaqSearch();
  new Footer();
});
