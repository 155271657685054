export default class DetailsTabs {
  constructor() {
    this.detailsTabs = document.querySelectorAll('.details-tabs');
    this.detailsTabs.forEach(detailsTabs => new DetailsTabsComponent(detailsTabs));
  }
}

class DetailsTabsComponent {
  constructor(container) {
    this.tabs = container.querySelectorAll('.details-tabs__tab');
    this.content = container.querySelector('.details-tabs__content');

    this.tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        const activeTab = container.querySelector('.details-tabs__tab--active');
        activeTab.classList.remove('details-tabs__tab--active');

        tab.classList.add('details-tabs__tab--active');
        this.content.innerHTML = tab.dataset.text;
      });
    });
  }
}
