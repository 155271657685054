import Swiper from 'swiper/core';


export default class HeroSwiper {
  constructor() {
    this.heroSwiper = document.querySelector('.hero-swiper');
    if (this.heroSwiper) {
      new Swiper(this.heroSwiper, {
        loop: true,
        speed: 800,
        simulateTouch: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        },
        navigation: {
          nextEl: '.hero-swiper__controls__next',
          prevEl: '.hero-swiper__controls__back'
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        }
      });
    }
  }
}
