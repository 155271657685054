import ToastMessage from '../shared/toast-message';
import { isDevMode, translations } from '../../shared';

export default class NewsletterForm {
  constructor() {
    this.forms = document.querySelectorAll('.newsletter-form');
    this.forms.forEach(form => {
      form.addEventListener('submit', async (e) => {
        e.preventDefault();

        try {
          const res = await fetch(form.getAttribute('action'), {
            method: form.getAttribute('method') || 'GET',
            body: new FormData(form)
          });

          const data = await res.json();
          new ToastMessage(data.message, res.ok ? 'success' : 'error');
          form.reset();
        } catch (e) {
          new ToastMessage(translations.get('newsletterError'), 'error');
          isDevMode && console.error(e);
        }
      });
    });
  }
}
